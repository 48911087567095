export const createSMSTemplateMutation = (builder) => ({
    createSMSTemplate: builder.mutation({
        query: (credentials) => {
            const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
            return {
                url: '/sms_template',
                method: 'POST',
                body: credentials,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }
        },
    }),
});