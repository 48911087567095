export const uploadMedia = (builder) => ({
    uploadMedia: builder.mutation({
      query: (credentials) => {
        return {
          url: '/upload',
          method: 'POST',
          body: credentials,
        };
      },
    }),
  });