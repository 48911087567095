export const deleteContactListMutation = (builder) => ({
    deleteList: builder.mutation({
        query: ({ id }) => {
            const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
            return {
                url: `/delete_contact_list/${id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }
        },
    }),
});
