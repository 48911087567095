export const userDropDownMutation = (builder) => ({
  userDropDown: builder.mutation({
    query: ({ user_type }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';

      return {
        url: '/user_details',
        method: 'GET',
        params: { user_type },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      };
    },
  }),
});