export const approveUserMutation = (builder) => ({
  approveUser: builder.mutation({
    query: ({ user_id, approved }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: '/approve_user',
        method: 'GET',
        params: { user_id, approved },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token 
        },
      };
    },
  }),
});
