export const blacklistSMSTemplatesMutation = (builder) => ({
    blacklistSMSTemplates: builder.mutation({
        query: ( temp_id ) => {
            const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
            return {
                url: `/sms_template/${temp_id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }
        },
    }),
});