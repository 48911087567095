import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { userLoginMutation } from '../api/Common/Login/login';
import { userOnboardingMutation } from 'api/SuperAdmin/UserOnboarding/userOnboarding';
import { userTypeMutation } from 'api/SuperAdmin/UserOnboarding/userType';
import { userDataMutation } from 'api/SuperAdmin/UserOnboarding/userData';
import { contactUploadListMutation } from 'api/Enterprise/ContactList/uploadList';
import { contactGetUploadListMutation } from 'api/Enterprise/ContactList/getUploadList';
import { userCheckMutation } from 'api/Common/CheckUser/checkUserDetails';
import { userDropDownMutation } from 'api/Common/UserDropDown/userDropDown';
import { verifyOtpMutation } from 'api/Common/Login/verifyOtp';
import { insertTransactionMutation } from 'api/SuperAdmin/Wallet/insertTransaction';
import { transactionalListMutation } from 'api/SuperAdmin/Wallet/getTransactionalList';
import { userUpdateFieldMutation } from 'api/SuperAdmin/UserOnboarding/userEditField';
import { approveUserMutation } from 'api/SuperAdmin/UserOnboarding/approveuser';
import { getUserBalanceMutation } from 'api/SuperAdmin/Wallet/getUserBalance';
import { deleteContactListMutation } from 'api/Enterprise/ContactList/deleteList';
import { getSMSTemplatesMutation } from 'api/Enterprise/Template/getSMSTemp';
import { createSMSTemplateMutation } from 'api/Enterprise/Template/createSMSTemp';
import { SMSTestingMutation } from 'api/SuperAdmin/ChannelTesting/smsTesting';
import { getCampaignListMutation } from 'api/Enterprise/Campaign/getCampaigns';
import { verifyCampaignNameMutation } from 'api/Enterprise/Campaign/verifyCampaignName';
import { deleteCampaignListMutation } from 'api/Enterprise/Campaign/deleteCampaignList';
import { getWhatsappTemplatesMutation } from 'api/Enterprise/Template/WhatsApp/getWhatsappTemp';
import { createWhatsAppMarketingMutation } from 'api/Enterprise/Template/WhatsApp/postMarketing';
import { WhatsAppTestingMutation } from 'api/SuperAdmin/ChannelTesting/whatsappTesting';
import { uploadMedia } from 'api/Common/mediaToUrl/mediaToUrl';
import { createCampaignMutation } from 'api/Enterprise/Campaign/createCampaign';
import { blacklistSMSTemplatesMutation } from 'api/Enterprise/Template/blacklistTemplate';


export const postApi = createApi({
  reducerPath: 'antiphishingServices',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_PORT,
  }),
  endpoints: (builder) => ({
    // Super-Admin
    ...userLoginMutation(builder),
    ...userOnboardingMutation(builder),
    ...userTypeMutation(builder),
    ...userDataMutation(builder),
    ...userCheckMutation(builder),
    ...userDropDownMutation(builder),
    ...verifyOtpMutation(builder),
    ...insertTransactionMutation(builder),
    ...transactionalListMutation(builder),
    ...userUpdateFieldMutation(builder),
    ...approveUserMutation(builder),
    ...getUserBalanceMutation(builder),
    ...SMSTestingMutation(builder),
    ...WhatsAppTestingMutation(builder),

    // Enterprise
    ...contactUploadListMutation(builder),
    ...contactGetUploadListMutation(builder),
    ...deleteContactListMutation(builder),
    ...createWhatsAppMarketingMutation(builder),
    ...getWhatsappTemplatesMutation(builder),
    ...getSMSTemplatesMutation(builder),
    ...createSMSTemplateMutation(builder),
    ...blacklistSMSTemplatesMutation(builder),
    ...getCampaignListMutation(builder),
    ...verifyCampaignNameMutation(builder),
    ...deleteCampaignListMutation(builder),
    ...uploadMedia(builder),
    ...createCampaignMutation(builder)



  }),
});

export const {
  useUserLoginMutation,
  useUserOnboardingMutation,
  useUserTypeMutation,
  useUserDataMutation,
  useContactUploadListMutation,
  useContactGetUploadListMutation,
  useDeleteListMutation,
  useCheckUserDetailsMutation,
  useUserDropDownMutation,
  useVerifyOtpMutation,
  useInsertTransactionMutation,
  useTransactionalListMutation,
  useGetWhatsappTemplatesMutation,
  useCreateWhatsappMarketingMutation,
  useUserUpdateFieldMutation,
  useApproveUserMutation,
  useGetUserBalanceMutation,
  useGetSMSTemplatesMutation,
  useCreateSMSTemplateMutation,
  useSmsTestingMutation,
  useGetCampaignListMutation,
  useVerifyCampaignNameMutation,
  useDeleteCampListMutation,
  useWhatsAppTestingMutation,
  useUploadMediaMutation,
  useCreateCampaignMutation,
  useBlacklistSMSTemplatesMutation,

} = postApi;
