export const deleteCampaignListMutation = (builder) => ({
    deleteCampList: builder.mutation({
        query: ({ id }) => {
            const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
            return {
                url: `/campaign/${id}`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }
        },
    }),
});
