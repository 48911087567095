//by shreya saraswat
import React, { Fragment, useState, useEffect, useCallback } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CalendarIcon, CheckIcon, CogIcon, DownloadIcon, HomeIcon, SearchIcon, XIcon } from "@heroicons/react/solid";
import { Row, Form, Button, InputGroup, Dropdown, Nav, ToastContainer, Toast, Card, Table, OverlayTrigger, Tooltip, Pagination, Breadcrumb, Col } from 'react-bootstrap';
import { useDeleteCampListMutation, useGetCampaignListMutation } from "Services/services";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import Select from 'react-select';
import Datetime from "react-datetime";
import { CurrencyRupeeIcon } from "@heroicons/react/outline";


const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));


export default () => {

    //usertype
    const userData = localStorage.getItem('TOKEN');
    const userObject = JSON.parse(userData);
    const userType = userObject.user_type;

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');

    const [tableData, setTableData] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
    const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

    const [pagelimit, setPagelimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState('');
    const [totalPosts, setTotalPosts] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');


    const [CampaignList] = useGetCampaignListMutation();

    // FORM PART
    const [formFields, setFormFields] = useState();
    const handleChoice = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.target.value,
        })
    }
    const sendRate = () => {
        //api
    }

    // Debounce search value
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(searchValue); // Set debounced value after 3 seconds
        }, 2000);
        // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
        return () => {
            clearTimeout(handler);
        };
    }, [searchValue]);



    const fetchTableData = useCallback(async () => {
        try {
            const response = await CampaignList({
                page: currentPage,
                limit: pagelimit,
                status: status,
                search: searchValue,
                startDate: startDate,
                endDate: endDate
            });

            if (response?.data?.statusCode === 200) {
                setTableData(response?.data?.data?.result?.data || []);
                setTotalPages(response?.data?.data?.result?.totalPages);
                setTotalPosts(response?.data?.data?.result?.totalPosts);
            } else {
                setTableData([]);
            }
        } catch (error) {
            alert(error.message);
            setTableData([]);
        }
    }, [currentPage, pagelimit, status, debouncedValue, startDate, endDate]);

    useEffect(() => {
        fetchTableData();
    }, [fetchTableData]);


    const changeSearchValue = (e) => {
        setSearchValue(e.target.value);
    };
    const handleStatus = (e) => {
        setStatus(e.target.value);
    };

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const reject = async (ids) => {
        console.log('ids', ids);
        const usersToBeDeleted = ids;
        // const usersNr = usersToBeDeleted.length;
        const textMessage = "Are you sure do you want to Reject this Enterprise?";

        const result = await SwalWithBootstrapButtons.fire({
            icon: "error",
            title: "Confirmation",
            text: textMessage,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel"
        });

        if (result.isConfirmed) {
            // const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.id));

            // deleteListApi({ id: ids })
            //     .then((response) => {
            //         if (response?.data?.statusCode === 200) {
            //             setMessage('The List has been deleted.')
            //         } else {
            //             setError('Failed to Delete.');
            //         }
            //     })
            //     .catch((error) => {
            //         setError('Error deleting this list.' || error);
            //     });

            const handleSwal = async () => {
                if (message !== '') {
                    await SwalWithBootstrapButtons.fire('Rejected', message, 'success');
                } else {
                    await SwalWithBootstrapButtons.fire('Failed', error, 'error');
                }
            };
            handleSwal();
        }
    }

    const approve = (ids) => {
        console.log('approve', ids);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const TableRow = (props) => {
        const statusVariant = props.status === "approved" ? "success"
            : props.status === "pending" ? "purple"
                : props.status === "rejected" ? "danger" : "primary";
        return (
            <tr className="border-bottom">
                <td><span className="fw-bold text-info">{props.pe_name}</span></td>
                <td><span className="fw-normal">{props.msg_type}</span></td>
                <td><span className="fw-bold text-info">{props.pe_name}</span></td>
                <td><span className="fw-normal">{moment(props.date).format('DD/MM/YYYY')}</span></td>
                <td><span className="fw-normal">{props.submitted_by}</span></td>
                <td><span className="fw-normal">{props.rate}</span></td>
                <td><span className={`fw-normal text-${statusVariant}`}>{props.status}</span></td>
                {userType === 'super_admin' && <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Approve</Tooltip>}>
                        <Card.Link onClick={() => approve(props.id)}>
                            <CheckIcon className="icon icon-xs text-success" />
                        </Card.Link>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Reject</Tooltip>}>
                        <Card.Link className="ms-2" onClick={() => reject(props.id)}>
                            <XIcon className="icon icon-xs text-danger" />
                        </Card.Link>
                    </OverlayTrigger>
                </td>}
            </tr>
        );
    };

    return (
        <Fragment>

            <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            <div className="d-flex align-items-start my-3">
                <div className="d-block">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item linkAs={Link}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Message Rate</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Message Rate</h4>
                </div>
            </div>

            <Card as="form" border="0" className="shadow p-4 mb-4" onSubmit={sendRate}>
                <Row>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>Enterprise</Form.Label>
                        <Select
                            placeholder="--Select User--"
                            className="mb-0"
                            isSearchable={true}
                        // options={users}
                        // value={user}
                        // onChange={(selectedOption) => {
                        //     setUser(selectedOption);
                        //     selectUserType(selectedOption);
                        // }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>Message Type</Form.Label>
                        <Form.Select name="channel" onChange={handleChoice}>
                            <option>SMS</option>
                            <option>WhatsApp</option>
                            <option>RCS</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>Route Name</Form.Label>
                        <Select
                            placeholder="--Select User--"
                            className="mb-0"
                            isSearchable={true}
                        // options={users}
                        // value={user}
                        // onChange={(selectedOption) => {
                        //     setUser(selectedOption);
                        //     selectUserType(selectedOption);
                        // }}
                        />
                    </Form.Group>

                    <Form.Group as={Col} className="mb-3">
                        <Form.Label>Rate</Form.Label>
                        <InputGroup>
                            <InputGroup.Text className="text-gray-600">
                                <CurrencyRupeeIcon className="icon icon-xs" />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Ex: ₹1,000"
                                onChange={handleChoice}
                            />
                        </InputGroup>
                    </Form.Group>

                    <Col xs={12}>
                        <Button variant="gray-800" className="animate-up-2 mt-2" type="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Card>

            <div className="table-settings">
                <Row className="justify-content-between align-items-center">
                    <div className="d-flex flex-wrap">
                        <InputGroup className="me-2 me-lg-3 d-flex flex-nowrap mb-3 fmxw-300">
                            <InputGroup.Text>
                                <SearchIcon className="icon icon-xs" />
                            </InputGroup.Text>
                            <Form.Control
                                type="search"
                                placeholder="Campaign Name/Template ID/Header"
                                value={searchValue}
                                onChange={changeSearchValue}
                            />
                        </InputGroup>
                        <Form.Select onChange={handleStatus} value={status} className="me-2 me-lg-3 mb-3 fmxw-300">
                            <option value="" defaultValue>All Channel</option>
                            <option>SMS</option>
                            <option>WhatsApp</option>
                            <option>RCS</option>
                        </Form.Select>
                        <Form.Group id="DateRange">
                            <InputGroup className="d-flex flex-nowrap">
                                <InputGroup.Text>
                                    <CalendarIcon className="icon icon-xs" />
                                </InputGroup.Text>
                                <Datetime
                                    timeFormat={false}
                                    onChange={setStart}
                                    renderInput={(props, openCalendar) => (
                                        <Form.Control
                                            required
                                            type="search"
                                            placeholder="Start Date"
                                            value={startDate}
                                            onFocus={openCalendar}
                                            style={{ width: "150px", borderRadius: "0" }}
                                            onChange={(e) => setStart(e.target.value)} />
                                    )} />
                                <Datetime
                                    timeFormat={false}
                                    onChange={setEnd}
                                    isValidDate={currDate => currDate.isAfter(start)}
                                    initialViewDate={end}
                                    renderInput={(props, openCalendar) => (
                                        <Form.Control
                                            required
                                            type="search"
                                            placeholder="End Date"
                                            value={endDate}
                                            onFocus={openCalendar}
                                            style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                                            onChange={(e) => setEnd(e.target.value)} />
                                    )} />
                            </InputGroup>
                        </Form.Group>

                        <Button variant="gray-300" className="ms-auto d-inline-flex mb-3 me-2" >
                            <DownloadIcon className="icon icon-xs" />
                        </Button>

                        <Dropdown className="mb-3">
                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                                <CogIcon className="icon icon-sm" />
                                <span className="visually-hidden">Toggle Dropdown</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                                <span className="small ps-3 fw-bold text-dark">Show</span>
                                {[10, 20, 30].map((limit) => (
                                    <Dropdown.Item
                                        key={limit}
                                        className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                                        onClick={() => setPagelimit(limit)}
                                    >
                                        {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Row>
            </div>

            <Card border="0" className="table-wrapper table-responsive shadow">

                <Card.Body>
                    <Table responsive className="align-items-center table-flush">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-bottom">Enterprise Name</th>
                                <th className="border-bottom">Message Type</th>
                                <th className="border-bottom">Route Name</th>
                                <th className="border-bottom">Date</th>
                                <th className="border-bottom">Submitted By</th>
                                <th className="border-bottom">Rate</th>
                                <th className="border-bottom">Status</th>
                                {userType === 'super_admin' && <th className="border-bottom">Action</th>}
                            </tr>
                        </thead>
                        <tbody className="border-0">
                            {tableData.length > 0 ? tableData.map((u, index) => (
                                <TableRow key={index + 1} index={index + 1} {...u} />
                            )) :
                                <tr>
                                    <td colSpan="7" className="text-center text-danger">No Data Found</td>
                                </tr>}
                        </tbody>
                    </Table>
                    <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
                        <Nav>
                            <Pagination className="mb-0">
                                <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                                    Previous
                                </Pagination.Prev>
                                {currentPage >= 3 && (
                                    <>
                                        <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                                        <Pagination.Ellipsis />
                                    </>
                                )}
                                {currentPage > 1 && (
                                    <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                        {currentPage - 1}
                                    </Pagination.Item>
                                )}
                                <Pagination.Item active>{currentPage}</Pagination.Item>
                                {currentPage < totalPages && (
                                    <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                        {currentPage + 1}
                                    </Pagination.Item>
                                )}
                                {currentPage < totalPages - 1 && (
                                    <>
                                        <Pagination.Ellipsis />
                                        <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                                            {totalPages}
                                        </Pagination.Item>
                                    </>
                                )}
                                <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                                    Next
                                </Pagination.Next>
                            </Pagination>

                        </Nav>
                        <small className="fw-normal small mt-2 mt-md-0">
                            Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
                        </small>
                    </Card.Footer>
                </Card.Body>
            </Card>

        </Fragment >
    );
};
