export const transactionalListMutation = (builder) => ({
  transactionalList: builder.mutation({
    query: ({ page, user_id, startDate, endDate, limit, search}) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';

      return {
        url: '/getTransactionList',
        method: 'GET',
        params: {  page, user_id, startDate, endDate, limit, search },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      };
    },
  }),
});