export const userCheckMutation = (builder) => ({
  checkUserDetails: builder.mutation({
    query: (credentials) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';

      return {
        url: '/check_user_details',
        method: 'POST',
        body: credentials,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token 
        },
      };
    },
  }),
});