import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { ArchiveIcon, CalendarIcon, ChatIcon, ChevronDownIcon, ClipboardListIcon, CloudUploadIcon, DocumentTextIcon, FireIcon, PlusIcon, PresentationChartBarIcon, ShoppingBagIcon, UsersIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Form, Image, Button, Dropdown } from 'react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget, NotificationsWidget } from "components/Widgets";
import { GeneralInfoForm } from "components/UserOnboarding";


export default () => {

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <GeneralInfoForm />
        </Col>
      </Row>
    </>
  );
};
