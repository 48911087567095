export const verifyCampaignNameMutation = (builder) => ({
  verifyCampaignName: builder.mutation({
    query: ({ campaign_name }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: '/check_campaign',
        method: 'GET',
        params: { campaign_name },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      }
    },
  }),
});