export const contactUploadListMutation = (builder) => ({
  contactUploadList: builder.mutation({
    query: (credentials) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: '/upload_list',
        method: 'POST',
        body: credentials,
        headers: {
          'Authorization': token
        },
      }
    },
  }),
});