export const getSMSTemplatesMutation = (builder) => ({
    getSMSTemplates: builder.mutation({
      query: ({ user_id = '', page = 1,  limit = '', search = '', category = '', status = '' }) => {
        const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
        return {
          url: '/sms_template',
          method: 'GET',
          params: { user_id, page, limit, search, category, status },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
        }
      },
    }),
  });