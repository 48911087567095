//by shreya saraswat
import React, { useState } from "react";
import { Col, Row, Tab, Nav, Breadcrumb, Button } from 'react-bootstrap';
import { SMSTemplate, TemplateTable } from "components/Tables";
import { HomeIcon } from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import { PlusIcon } from "@heroicons/react/solid";


export default () => {
    const location = useLocation();
    const [activeKey, setActiveKey] = useState(location.state?.activeTab || "SMS");
    //usertype
    const userData = localStorage.getItem('TOKEN');
    const userObject = JSON.parse(userData);
    const userType = userObject.user_type;


    return (
        <>
            <div className="d-flex align-items-center my-3">
                <div className="d-block">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item>MAT</Breadcrumb.Item>
                        <Breadcrumb.Item active>Template Lists</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Template Lists</h4>
                    <p className="mb-0">Your SMS/WhatsApp/RCS template analytics dashboard.</p>
                </div>
                {userType === 'enterprise' && <Button as={Link} to={Routes.CreateTemplate.path} variant="secondary" size="sm" className="ms-auto d-inline-flex align-items-center">
                    <PlusIcon className="icon icon-xs me-2" /> New Template
                </Button>}
            </div>

            <Row className="mt-3">
                <Col xs={12} xl={12}>
                    <Tab.Container defaultActiveKey={activeKey} onChange={setActiveKey}>
                        <Nav fill variant="pills" className="flex-column flex-sm-row">
                            <Nav.Item>
                                <Nav.Link eventKey="SMS">
                                    SMS
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="WhatsApp">
                                    WhatsApp
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="RCS">
                                    RCS
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content className="mt-0 mt-lg-3">
                            <Tab.Pane eventKey="SMS">
                                <SMSTemplate />
                            </Tab.Pane>

                            <Tab.Pane eventKey="WhatsApp">
                                <TemplateTable />
                            </Tab.Pane>

                            <Tab.Pane eventKey="RCS">
                                coming soon !
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>
        </>
    );
};
