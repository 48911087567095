export const userUpdateFieldMutation = (builder) => ({
  userUpdateField: builder.mutation({
    query: (credentials) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';

      return {
        url: '/update_user_field',
        method: 'POST',
        body: credentials,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token 
        },
      };
    },
  }),
});
