export const userTypeMutation = (builder) => ({
  userType: builder.mutation({
    query: () => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';

      return {
        url: '/user_type_details',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      };
    },
  }),
});
