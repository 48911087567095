export const contactGetUploadListMutation = (builder) => ({
  contactGetUploadList: builder.mutation({
    query: ({ user_id = '', page = 1, limit = '', search = '', fromDate = '', toDate = '', list_type }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: '/get_uploaded_list',
        method: 'GET',
        params: { user_id, page, limit, search, fromDate, toDate, list_type },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      }
    },
  }),
});