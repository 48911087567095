import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';

const CopiedAlert = ({ showAlert, setShowAlert, value }) => {
  
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000); 

      return () => clearTimeout(timer);
    }
  }, [showAlert, setShowAlert]);

  return (
    <Alert
      variant="success"
      show={showAlert}
      onClose={() => setShowAlert(false)}
      dismissible
      style={{
        position: 'fixed',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 9999,
        width: 'auto',
        textAlign: 'center'
      }}
    >
      {value}
    </Alert>
  );
};

export default CopiedAlert;
