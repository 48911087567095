// export const createCampaignMutation = (builder) => ({
//     createSMSTemplate: builder.mutation({
//         query: (credentials) => {
//             const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
//             return {
//                 url: '/sms_template',
//                 method: 'POST',
//                 body: credentials,
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': token
//                 },
//             }
//         },
//     }),
// });

export const createCampaignMutation = (builder) => ({
    createCampaign: builder.mutation({
        query: (formData) => {
            const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
            return {
                url: '/campaign', // Adjusted to match your curl endpoint
                method: 'POST',
                body: formData, // Pass formData directly as the request body
                headers: {
                    'Authorization': token,
                },
            };
        },
    }),
});
