export const userDataMutation = (builder) => ({
  userData: builder.mutation({
    query: ({ pageNumber, limit, user_type, search, status }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';

      return {
        url: '/user_data',
        method: 'GET',
        params: { pageNumber, limit, user_type, search, status },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      };
    },
  }),
});
