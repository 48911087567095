export const getUserBalanceMutation = (builder) => ({
  getUserBalance: builder.mutation({
    query: ({ user_id }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';

      return {
        url: '/get_user_balance',
        method: 'GET',
        params: { user_id },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      };
    },
  }),
});