export const getCampaignListMutation = (builder) => ({
  getCampaignList: builder.mutation({
    query: ({ page = 1, limit = '', status, search, startDate, endDate, campaign_type }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
      return {
        url: '/campaign',
        method: 'GET',
        params: { page, limit, status, search, startDate, endDate, campaign_type },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      }
    },
  }),
});